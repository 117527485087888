import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import ReactMarkdown from 'react-markdown';
import { unitConverter as uc } from '../../../styles/base';

const defaultCss = css`
  & :first-child,
  & :last-child {
    padding: ${uc('12px')};
  }
  vertical-align: middle;
`;

const tableHeaderCss = css`
  ${defaultCss}
  padding: ${uc('12px')};
  text-align: center;
  background-color: #f5f9fc;
  border-bottom: ${uc('2px')} solid #dee2e6;
`;

const renderers = {
  image: () => null,
  link: () => null,
  // eslint-disable-next-line react/prop-types
  heading: ({ children }) => <b>{children}</b>,
  // eslint-disable-next-line react/prop-types
  text: ({ children }) => <>{children}</>,
  // eslint-disable-next-line react/prop-types
  paragraph: ({ children }) => <>{children}</>,
};

const TableCell = ({ children, header }) => {
  if (header) {
    return (
      <th css={tableHeaderCss}>
        <ReactMarkdown
          source={children}
          escapeHtml={false}
          renderers={renderers}
        />
      </th>
    );
  }
  return (
    <td css={defaultCss}>
      <ReactMarkdown
        source={children}
        escapeHtml={false}
        renderers={renderers}
      />
    </td>
  );
};

TableCell.propTypes = {
  header: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

TableCell.defaultProps = {
  header: false,
};

export default TableCell;
